<template>
    <div class="with">
        <div class="flex-shrink">
            <div class="query">
                <Form
                ref="form"
                :formSchema="withformSchema"
                >
                    <li style="width: 25%;" class="pl20">
                        <el-button type="primary" @click="onAjax">查询</el-button>
                        <el-button @click="onReset">重置</el-button>
                        <el-button @click="onExpand" type="text">{{ this.show ? '收起' : '展开' }}<i class="el-icon--right" :class="show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></el-button>
                    </li>
                </Form>

            </div>
            <div class="operate">
                <div class="item" @click="onAjax">
                    <el-button icon="el-icon-refresh">刷新</el-button>
                </div>
                <div class="item">
                    <el-button icon="el-icon-plus" @click="onAdd">添加</el-button>
                </div>
                <div class="item">
                    <el-button icon="el-icon-set-up" @click="onRenewal">手动续期</el-button>
                </div>
                <div class="item">
                    <el-dropdown trigger="click" @command="onBatchDelete">
                        <el-button icon="el-icon-s-fold">
                            批量操作
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-delete" command="onEnable">启用</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-delete" command="onDisabled">禁用</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-delete" command="onDelete">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="item">
                    <!-- <el-button type="primary">导出</el-button> -->
                </div>
            </div>
        </div>
        <Table
        :tableFields="withdrawalFields"
        :tableData="tableData"
        @handleCurrentChange="handleCurrentChange"
        checked
        :total="total"
        ref="table"
        @selection-change="selectionChange"
        >
            <template #operate="{ row }">
                <el-button type="text" v-if="row.status == '1'" class="link b4" @click="onDisabled(row)">禁用</el-button>
                <el-button type="text" v-else class="link b3" @click="onEnable(row)">启用</el-button>
                <el-button type="text" class="link b1" @click="onEdit(row)">编辑</el-button>
                <el-button type="text" class="link b2" @click="onDelete(row)">删除</el-button>
            </template>
        </Table>
        <!-- 手工续期 -->
        <DialogForm
        ref="renewal"
        :formSchema="formSchema"
        title="手工续期"
        width="600px"
        @confirm="renewal"
        >
        </DialogForm>

        <DialogForm
        ref="dialog"
        :formSchema="formSchema1"
        :title="title"
        width="660px"
        :labelWidth="140"
        @confirm="onConfirm"
        >
        </DialogForm>
    </div>
</template>

<script>
    import { withdrawalFields, withformSchema, formSchema, formSchema1 } from './index'
    import helper from '@/common/helper.js';
    export default {
        data(){
            return {
                title: '添加',
                formSchema,
                formSchema1,
                withformSchema,
                show: false,
                tableData: [],
                withdrawalFields,
                loading: true,
                total: 0,
                form: {
                    page: 1,
                    limit: 10,
                },
                select: []
            }
        },
        mounted(){
            this.onAjax()
        },
        methods: {
            renewal(data){
                console.log(data);
                this.$http.api('api/Agent/AgentAccountRenew', {id: this.select.map(item=>item.id).join(',')})
                .then(res=>{
                    this.$message.success(res.msg)
                })
            },
            onExpand(){
                this.show = !this.show
                this.$refs.form.setData({
                    id: this.show ? '1' : ''
                })
                this.$refs.table.onResize()
            },
            onReset(){
                this.$refs.form.resetFields()
                this.onAjax()
            },
            handleCurrentChange(page){
                console.log(page);
            },
            selectionChange(e){
                this.select = e
            },
            onAjax(){
                this.$refs.form.validate((is, data)=>{
                    if(is){
                        const params = helper.timerHandle(data, 'expire', 'expire_start', 'expire_end')
                        const params2 = helper.timerHandle(params, 'start', 'start_date', 'end_date')
                        this.$http.api('api/Agent/AgentAccount', helper.clearUseless({...params2, ...this.form}))
                        .then(res=>{
                            this.tableData = res.data.data
                            this.total = res.data.total
                        })
                        .finally(()=>{
                            this.loading = false
                        })
                    }
                })
            },
            onBatchDelete(command){
                if(this.select.length == 0){
                    this.$message.info("请先选择")
                    return false
                }
                const data = {id: this.select.map(item=>item.id).join(',')}
                this[command](data)
            },
            onDelete({ id }){
                this.$confirm({
                    title: '提示',
                    content: '确定删除此信息吗？',
                    onOk: ()=>{
                        this.$http.api('api/Agent/AgentAccountDel', { id })
                        .then(res=>{
                            this.$message.success(res.msg)
                            this.onAjax()
                        })
                    }
                })
            },
            onRenewal(){
                if(this.select.length == 0){
                    this.$message.info("请先选择")
                    return false
                }
                this.$refs.renewal.onShow()
            },
            onAdd(){
                this.title = '添加'
                this.$refs.dialog.onShow()
            },
            onEdit(item){
                this.title = '修改'
                this.$refs.dialog.onShow(true, item)
            },
            /** 启用 */
            onEnable({ id }){
                this.$http.api('api/Agent/AgentAccountEnable', { id })
                .then(res=>{
                    this.onAjax()
                    this.$message.success(res.msg)
                })
            },
            onDisabled({ id }){
                this.$http.api('api/Agent/AgentAccountDisable', { id })
                .then(res=>{
                    this.onAjax()
                    this.$message.success(res.msg)
                })
            },
            onConfirm(data){
                this.$http.api('api/Agent/AgentAccountSave', data)
                .then(res=>{
                    this.$message.success(res.msg)
                    this.onAjax()
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .query{
        background: #fff;
        padding: 7px 20% 7px 0;
    }
    .operate{
        display: flex;
        padding: 0 0 15px 15px;
        background-color: #fff;
        border-bottom: 1px solid #ebeef5;
        .item{
            margin-right: 10px;
        }
    }
    .table{
        
    }
    .link{
        color: #fff;
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 2px;
        margin: 0 4px;
        &.b1{
            background: #e74c3c;
        }
        &.b2{
            background: #ff9900;
        }
        &.b3{
            background: #4d9cea;
        }
        &.b4{
            background: #ef8947;
        }
    }
    .with{
        background: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        .flex-shrink{
            flex-shrink: 0;
        }
    }
</style>