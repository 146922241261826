import { getDictList } from './api'

export const formSchema = [
    {
        field: 'abc',
        label: '续期至',
        component: 'el-date-picker',
        required: true,
        style: {
            width: '100%'
        },
        componentProps: {
            placeholder: "请选择日期",
            valueFormat: 'yyyy-MM-dd'
        }
    },
    {
        field: 'abc2',
        label: '续期金额',
        required: true,
        component: 'Input',
        style: {
            width: '100%'
        },
        componentProps: {
            placeholder: '请输入金额'
        }
    },
    {
        field: 'abc1',
        label: '备注',
        component: 'el-input',
        style: {
            width: '100%',
        },
        componentProps: {
            type: "textarea",
            placeholder: "请输入内容",
            rows: "2"
        }
    },
]

export const formSchema1 = [
    {
        field: 'id',
        label: 'ID',
        component: 'Id',
        show: false,
        defaultValue: '0',
    },
    {
        field: 'account',
        label: '代理人账号',
        component: 'Input',
        required: true,
        style: {
            width: '100%'
        },
        componentProps: {
            placeholder: '请输入代理人账号'
        }
    },
    {
        field: 'password',
        label: '账号密码',
        required: true,
        component: 'Input',
        style: {
            width: '100%'
        },
        componentProps: {
            placeholder: '请输入主账号密码(6~12位)'
        }
    },
    {
        field: 'class_id',
        label: '代理类型',
        required: true,
        component: 'Select',
        style: {
            width: '100%'
        },
        componentProps: {
            placeholder: '请选择代理类型',
            api: getDictList,
        }
    },
    {
        field: 'name',
        label: '代理人姓名',
        required: true,
        component: 'Input',
        style: {
            width: '100%'
        },
        componentProps: {
            
        }
    },
    {
        field: 'mobile',
        label: '代理人手机',
        component: 'Input',
        required: true,
        style: {
            width: '100%'
        },
        componentProps: {
            
        }
    },
    {
        field: 'admin_name',
        label: '代理门店后台名称',
        component: 'Input',
        required: true,
        style: {
            width: '100%',
        },
        componentProps: {
            
        }
    },
    {
        field: 'admin_logo_url',
        label: '门店后台登录',
        required: true,
        component: 'Upload',
        style: {
            width: '40%',
        },
        componentProps: {
            desc: '使用白色透明背景'
        }
    },
    {
        field: 'admin_backend_url',
        label: '门店后台应用',
        required: true,
        component: 'Upload',
        style: {
            width: '50%',
        },
        componentProps: {
            desc: '使用白色透明背景'
        }
    },
    {
        field: 'source',
        label: '来源渠道',
        component: 'Input',
        style: {
            width: '100%'
        },
        componentProps: {
            placeholder: '请输入来源渠道'
        }
    },
    {
        field: 'status',
        label: '状态',
        component: 'u-Switch',
        style: {
            width: '100%',
        },
        componentProps: {
            custom: {
                true: '1',
                false: '0'
            }
        }
    },
]

export const withdrawalFields = [
    // {
    //     prop: 'sequ',
    //     label: '序号',
    //     width: 50,
    // },
    {
        prop: 'id',
        label: '代理ID',
    },
    {
        prop: 'account',
        label: '代理人账号',
    },
    {
        prop: 'admin_name',
        label: '代理人信息',
    },
    {
        prop: 'class_id_name',
        label: '代理类型',
    },
    {
        prop: 'expire_time',
        label: '授权到期',
    },
    {
        prop: 'is_end',
        label: '创建门店数',
    },
    {
        prop: 'source',
        label: '来源渠道',
    },
    {
        prop: 'status_name',
        label: '状态',
    },
    {
        prop: 'add_time',
        label: '创建时间',
    },
    {
        prop: 'operate',
        label: '操作',
        width: 200,
    },
]

export const withformSchema = [
    {
        field: 'id',
        show: false,
    },
    {
        field: 'account ',
        label: '代理账号',
        component: 'Input',
        style: {
            width: '25%'
        }
    },
    {
        field: 'class_id',
        label: '代理类型',
        component: 'Select',
        style: {
            width: '25%'
        },
        componentProps: {
            api: getDictList
        }
    },
    
    {
        field: 'expire',
        label: '授权到期',
        component: 'el-date-picker',
        style: {
            width: '25%'
        },
        ifShow: ({ id })=>{
            return !!id
        },
        componentProps: {
            type: "daterange",
            startPlaceholder: "开始日期",
            endPlaceholder:"结束日期",
            valueFormat: 'yyyy-MM-dd'
        }
    },
    {
        field: 'is_end',
        label: '是否到期',
        component: 'Select',
        style: {
            width: '25%'
        },
        ifShow: ({ id })=>{
            return !!id
        },
        componentProps: {
            options: [
                {
                    value: '1',
                    label: '未到期'
                },
                {
                    value: '2',
                    label: '已到期'
                },
            ]
        }
    },
    {
        field: 'status',
        label: '选择状态',
        component: 'Select',
        style: {
            width: '25%'
        },
        ifShow: ({ id })=>{
            return !!id
        },
        componentProps: {
            options: [
                {
                    value: '1',
                    label: '启用'
                },
                {
                    value: '2',
                    label: '禁用'
                },
            ]
        }
    },
    {
        field: 'source',
        label: '来源渠道',
        component: 'Input',
        style: {
            width: '25%'
        },
        ifShow: ({ id })=>{
            return !!id
        },
    },
    {
        field: 'start',
        label: '创建时间',
        component: 'el-date-picker',
        style: {
            width: '25%'
        },
        componentProps: {
            type: "daterange",
            startPlaceholder: "开始日期",
            endPlaceholder:"结束日期",
            valueFormat: 'yyyy-MM-dd'
        }
    },
]